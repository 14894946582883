<template>
  <div>
    <!-- <Button
      data-toggle="modal"
      data-target="#SortProductModal"
      btnVariant="yellow"
    >
      Sort Products</Button
    > -->
    <div
      class="modal fade"
      ref="sortProduct"
      id="SortProductModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="SortProductModalContent"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container ModalContainer">
              <div class="card p-4" style="border: none">
                <div
                  class=""
                  style="min-height: 65vh"
                  v-if="isAllProductLoading"
                >
                  <LoaderComp />
                </div>
                <div v-else>
                  <div
                    class="row d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h4 class="font-weight-bold" style="color: #4d4950">
                        Easily Rearrange Products
                      </h4>
                      <p class="" style="color: #4d4950">
                        Drag and drop to reorder or manually type in a rank
                        number for precise positioning.
                      </p>
                    </div>
                    <div class="d-flex">
                      <button
                        @click="updateDataOrder"
                        class="btn mr-2"
                        style="
                          background: #4d1b7e;
                          border-radius: 6px;
                          color: #ffffff;
                        "
                      >
                        Save
                      </button>
                      <button
                        @click="closeModal"
                        class="outline-none outline-none border-0 px-2 py-1 text-white"
                        style="
                          cusrsor: pointer;
                          border-radius: 6px;
                          background: #b3afb6;
                        "
                      >
                        Close
                      </button>
                    </div>
                  </div>

                  <div
                    class=" mt-2"
                    v-if="Products"
                    style="max-height:55vh; min-height: 55vh; overflow: auto"
                  >
                  <!-- <draggable v-model="Products"
                        tag="tbody"
                        class=""
                        style="cursor: grab" :options="{animation: 150}">
      <b-table :items="Products" :fields="columns">
        
        <template #cell(rank)="data">
          {{ data.item.data_order }}
        </template>
         <template #cell(image)="data">
              <video
                              v-if="checkFileType(data.item.image) == 'video'"
                              ref="videoRef"
                              :src="`${data.item.image}`"
                              style="
                                object-fit: cover;
                                border-radius: 6px;
                                height: 50px;
                                width: 50px;
                                cursor: pointer;
                              "
                              controls
                            ></video>

                            <LazyImage
                              v-else
                              :src="ImageSrc(data.item.image)"
                              :key="data.item.image"
                              style="
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                                cursor: pointer;
                              "
                              alt=""
                            />
        </template>
      </b-table>
    </draggable> -->
                    <table
                      class="table w-100  table-sm SortResponsiveTable"
                      id="SortProductTableUpdate"
                      style="text-align: left!important;"
                    >
                      <thead
                        class="w-100 stickyHeader bg-table-heading text-left"
                         style="text-align: left!important;"
                      >
                        <tr style="height: 50px">
                          <th scope="col"
                            class="text-left p-2 bg-table-heading text-left"
                             style="text-align: left!important;  min-width: 20px;
                              max-width: 20px;"></th>
                          <th
                            scope="col"
                            class="text-left p-2 bg-table-heading text-left"
                             style="text-align: left!important;"
                          >
                            Rank
                          </th>
                         
                          <th
                            scope="col"
                            class="ProductInfo min-width-tr p-2 bg-table-heading text-left"
                            style="text-align: left!important;"
                          >
                            Name
                          </th>
                        </tr>
                      </thead>

                      <draggable
                        v-model="Products"
                        tag="tbody"
                        class=""
                        style="cursor: grab"
                         handle=".handle"
                      >
                        <tr
                          v-for="(item, index) in Products"
                          :key="`${item.id}-${index}`"
                          class="TableRowStyle"
                          style="text-align: left!important;"
                        >
                         <td
                          class="align-middle data-Table cursor-pointer"
                            style="
                              vertical-align: middle;
                              text-align: center;
                              min-width: 20px;
                              max-width: 20px;
                              text-align: left!important;
                            "
                          > <i class="fa p-2 fa-align-justify handle" style="cursor:all-scroll"></i></td>
                          <td
                          class="align-middle data-Table cursor-pointer"
                            style="
                              vertical-align: middle;
                              text-align: center;
                              min-width: 100px;
                              text-align: left!important;
                            "
                          >
                          
                            <div class="ProductInfo">
                              <div v-if="item.editOrder">
                                <div class="d-flex align-items-center">
                                  <b-form-input
                                    type="number"
                                    min="0"
                                    class="text-center"
                                    style="background: #f6f6f7; width: 80px"
                                    placeholder="Search Products"
                                    v-model="item.data_order"
                                  ></b-form-input>
                                  <button
                                    class="btn"
                                    @click="OrderUpdate(item)"
                                  >
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div
                                class="d-flex align-items-center pl-3"
                                v-else
                              >
                                <p class="UserName m-0 mr-3">
                                  {{ item.data_order }}
                                </p>
                                <button
                                  class="btn"
                                  @click="toggleEditOrder(item,index) "
                                >
                                  <i
                                    class="fa fa-pencil"
                                    style="color: #4b494e"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>

                              
                            </div>
                          </td>

                          <td
                            class="ProductInfo align-middle data-Table cursor-pointer"
                            style="vertical-align: middle; text-align: left!important;"
                          >
                            <video
                              v-if="checkFileType(item.image) == 'video'"
                              ref="videoRef"
                              :src="`${item.image}`"
                              style="
                                object-fit: cover;
                                border-radius: 6px;
                                height: 50px;
                                width: 50px;
                                cursor: pointer;
                              "
                              controls
                            ></video>

                            <LazyImage
                              v-else
                              :src="ImageSrc(item.image)"
                              :key="item.image"
                              style="
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                                cursor: pointer;
                              "
                              alt=""
                            />
                            <span
                              class="ProductTitle ProductInfo px-3 m-0"
                              v-if="item.title.length < 60"
                            >
                              {{item.title}}
                            </span>
                            <span class="ProductTitle ProductInfo px-3 m-0" v-else>
                              {{ item.title.slice(0, 60) }}...
                            </span>
                           
                          </td>
                        
                        </tr>
                      </draggable>
                    </table>
                  </div>

                  <!-- If PRoduct is Empty -->
                  <div v-else>
                    <h3 class="text-center">No Product Found!</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Button from "../../Layout/Button.vue";
import LoaderComp from "../../../customize/component/LoaderComp.vue";
import $ from "jquery";
import draggable from "vuedraggable";
export default {
  components: {
    LoaderComp,
    draggable,
    // Button,
  },
  data() {
    return {
      isAllProductLoading: false,
      Products: [],
      oldOrder: null,
         columns: [
        {
          key: "rank",
          label: "Rank",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "image",
          label: "Image",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "title",
          label: "Title",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        
      ],
    };
  },
  computed:{
    getPRoducts(){
      return this.Products
    }
  },
  methods: {
    toggleEditOrder(item,index) {
      if (!item.editOrder) {
        
        this.Products[index].editOrder = true
        this.saveOldOrder(item);
      }
    },
    saveOldOrder(item) {
      this.oldOrder = item.data_order;
    },
    async updateDataOrder() {
      let newArray = [];
      Object.entries(this.Products).forEach(([key, value]) => {
        newArray.push({
          id: value["id"],
          data_order: parseInt(key),
        });
      });
      //  Trigger api to save data
      try {
        this.isAllProductLoading = true;
        let data = {
          data: newArray,
        };
        const response = await axios.post(`/saveSortProductOrder`, data);
        if (response.status == 200) {
          this.$toasted.show("Product Order updated", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isAllProductLoading = false;
        this.getAllProducts();
      }
    },
    ImageSrc(img) {
      if(img)
      return img.startsWith("uploads") ? `/${img}` : img;
    return 'https://images.quizell.com/website/default_start_page.png'
    },
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    ModifyArray(array) {
      if (array.length) {
        for (let i = 0; i < array.length; i++) {
          array[i].data_order = i + 1;
          array[i].editOrder = false;
        }

        return array;
      } else {
        return [];
      }
    },
    async getAllProducts() {
      this.isAllProductLoading = true;
      try {
        let data = {
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/loadQuizProducts`, data);
        if (response.status == 200) {
          // this.Products =  response.data.data.data;
          this.Products = response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isAllProductLoading = false;
      }
    },
    closeModal() {
      $("#SortProductModal").modal("hide");
      this.$emit("updateProducts");
    },
    OrderUpdate(item) {
      // let movedItem = item
      let newIndex = item.data_order - 1;
      let oldIndex = this.oldOrder - 1;
      let arr = [...this.Products];

      if (newIndex <= -1) {
        this.$toasted.show("Invalid Input", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } else {
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      }

      this.Products = this.ModifyArray(arr);
      this.oldOrder = null;
      // this.updateDataOrder()
    },
  },
  mounted() {
    $(this.$refs.sortProduct).on("show.bs.modal", this.getAllProducts);
    $(this.$refs.sortProduct).on("hidden.bs.modal", this.closeModal);
  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 20px;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
  font-size: 14px;
  border-radius: 6px;
}
.modal-btn:active {
  color: #ffffff;
  background: #976206;
}

.ModalContainer {
  height: 80vh;
}

/* #SortTable .tableBody > tr{
  height:30px !important;
  max-height:30px !important;
} */
/* .tableBody > tr{
  height:30px !important;
  max-height:30px !important;
} */

/* .table-striped tbody tr{
  height:30px !important;
  max-height:30px !important;
} */

/* .tableBody tr{
    max-height:30px !important;
} */

.ProductInfo {
  text-align: center !important;
}
/* .custom-select:focus option:checked{
  background-color:  #9c4629!important;
  color: rgb(255, 255, 255);
}
option:checked {
    background-color:  #9c4629!important;
    color: rgb(255, 255, 255);
}
option:hover {
   box-shadow: 0 0 10px 100px #9c4629 inset;
   background-color:  #9c4629!important;
    color: rgb(255, 255, 255);
} */
.ProductTitle:hover {
  text-decoration: underline !important;
}

/* #SortProductTableUpdate td {
  border-top: 1px solid #4d1b7e !important;
} */

.w-90 {
  width: 90%;
}

@media screen and (max-width: 750px) {
  .ProductInfo {
    margin-bottom: 0 !important;
  }

  .SortResponsiveTable thead {
    display: none;
  }

  .SortResponsiveTable,
  .SortResponsiveTable tbody,
  .SortResponsiveTable tr,
  .SortResponsiveTable td {
    display: block;
    width: 100%;
  }

  .SortResponsiveTable tr {
    margin-bottom: 15px;
  }

  #SortProductTableUpdate td {
    border-top: none !important;
  }

  .SortResponsiveTable td {
    text-align: center;
    /* padding-left: 50%; */
    position: relative;
  }

  .SortResponsiveTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }
}

.min-width-tr {
  min-width: 100px;
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background: #f2f2f2;
  vertical-align: middle;
}
</style>
